import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';

import { useBackground } from '../../hooks/use-background';
import { getImage } from '../../helpers';
import { Criteo } from '~/components/Criteo/Criteo';
import SEO from '~/components/seo';
import { DeriveHeaderClasses } from '~/components/Navigation';
import { LoginForm } from '~/components/AccountPages';
import { HeadingLarge, BodyText } from '~/components/.base/headings';
import { FormControl } from '~/components/.base/inputs';
import { RedButton } from '~/components/.base/buttons';
import Modal from '~/components/.base/modal';
import { BackgroundDiv } from '~/components/.base/containers';
import {
  authenticateShopifyCustomer,
  sendForgotPWEmail,
} from '~/helpers/requests/customer-requests';
import Cookies from 'js-cookie';
import './account.scss';
import { css } from 'styled-components';

const LoginPage = ({ location }) => {
  const backgroundImages = useBackground().edges;

  const [authErrorMessage, setAuthErrorMessage] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [forgotPWEmail, setForgotPWEmail] = useState('');

  const forgotPasswordEmailFieldRef = useRef(null);
  useEffect(() => {
    if (modalIsOpen)
      if (forgotPasswordEmailFieldRef?.current) forgotPasswordEmailFieldRef.current.focus();
  }, [modalIsOpen]);

  useEffect(() => {
    const accessToken = Cookies.get('KEPT_SESS');
    if (accessToken) window.location.href = '/account/dashboard';
  }, []);

  const submitForm = values => {
    authenticateShopifyCustomer(values.email, values.password).then(res => {
      // set cookie with access token / exp.date

      const { customerAccessTokenCreate } = res.data.data;
      if (!customerAccessTokenCreate.userErrors.length) {
        const { accessToken, expiresAt } = customerAccessTokenCreate.customerAccessToken;
        const expiryDate = new Date(expiresAt);
        Cookies.set('KEPT_SESS', accessToken, { expires: expiryDate });
        window.location.href = '/account/dashboard';
      } else {
        setAuthErrorMessage('Invalid credentials');
      }
    });
  };
  const handleForgetPWEmailSubmitStatus = ({ message }) => {
    setModalIsOpen(false);
    if (message) {
      setAuthErrorMessage(message);
    }
  };
  const submitForgotPW = e => {
    e.preventDefault();
    sendForgotPWEmail(forgotPWEmail).then(res => {
      if (res.data.errors && res.data.errors.length > 0) {
        let message = '';
        res.data.errors.forEach(error => {
          message += message + error.message + ' ';
        });
        handleForgetPWEmailSubmitStatus({ message });
        return null;
      }
      if (!res.data.data.customerRecover.customerUserErrors.length) {
        handleForgetPWEmailSubmitStatus({
          message: 'Reset sent successfully!',
        });
        return null;
      } else {
        let message = '';
        res.data.data.customerRecover.customerUserErrors.forEach(error => {
          message += message + error.message + ' ';
        });
        handleForgetPWEmailSubmitStatus({
          message,
        });
      }
    });
  };
  return (
    <div className="auth login">
      <SEO title="Login" />
      <Criteo />
      <DeriveHeaderClasses location={location} />
      <BackgroundDiv
        right="50px"
        top="150px"
        z="-1"
        css={css`
          max-height: 100%;
          overflow: hidden;
          .gatsby-image-wrapper {
            width: 901px;
            max-width: 100%;
          }
        `}
      >
        <Image fluid={getImage(backgroundImages, 'blurred_smudge_3')} alt="" />
      </BackgroundDiv>
      <HeadingLarge md="0 0 .75rem" align="center">
        <span className="red">Login</span>
      </HeadingLarge>
      <BodyText align="center">Please fill in the information below:</BodyText>
      <LoginForm
        submitForm={submitForm}
        setModalIsOpen={setModalIsOpen}
        authErrorMessage={authErrorMessage}
        forgotPasswordEmailFieldRef={forgotPasswordEmailFieldRef}
      />
      <BodyText md="1rem" align="center">
        Don’t have an account?
        <Link to="/account/register">Create one</Link>
      </BodyText>
      <Modal
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        ariaLabelledby="recover-password-modal-title"
        top="0"
        modalMaxWidth="700px"
      >
        <div className="forgot-pw-modal-inner">
          <HeadingLarge id="recover-password-modal-title" md="0 0 2rem">
            <span className="red">Recover Password</span>
          </HeadingLarge>
          <form onSubmit={submitForgotPW}>
            <FormControl className="input">
              <label htmlFor="email">Email</label>
              <input
                ref={forgotPasswordEmailFieldRef}
                id="email"
                name="email"
                type="email"
                className="field"
                placeholder="Email"
                value={forgotPWEmail}
                onChange={e => setForgotPWEmail(e.target.value)}
                autoComplete="email"
                required
              />
            </FormControl>
            <RedButton>Reset</RedButton>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default LoginPage;
